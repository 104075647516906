.card_main {
  border: 2px solid #eee;
  border-radius: 1rem;
  width: calc(25% - 1.2rem);
  min-width: 15rem;
}

.card_main_details {
  padding: 1rem;
}

.card_main_details h2 {
  font-size: 16px;
  height: 2.3rem;
  margin: 0;
}

.card_main_details span {
  background: linear-gradient(223.23deg, #fff -39.74%, #dbf5f9 94.44%);
  padding: 3px 5px;
  border-radius: 5px;
  font-weight: 500;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
  color: gray;
  text-align: left;
  margin: 0;
  margin-top: 1rem;
}

.price_div {
  display: flex;
  flex-direction: column;
}

.price {
  margin: 0;
  font-size: 22px;
  color: #002e81;
  font-weight: 500;
}

.mrp {
  margin: 0;
  text-decoration-line: line-through;
  color: gray;
}

.buttons_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #eee;
  border-bottom-left-radius: 0.9rem;
  border-bottom-right-radius: 0.9rem;
  padding: 0.8rem;
}

.plan_btn {
  font-size: 0.8rem !important;
  text-transform: uppercase !important;
  font-weight: 750 !important;
  border: none;
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #002e81;
  background-color: #002e81;
  color: #fff;
  cursor: pointer;
}

.plan_btn2 {
  font-size: 0.62rem !important;
  text-transform: uppercase !important;
  font-weight: 750 !important;
  border: none;
  padding: 0.5rem;
  border-radius: 0.2rem;
  border: 1px solid #002e81;
  color: #002e81;
  background-color: #fff;
  cursor: pointer;
}

/* TestDetails.css */

.test_details_container {
  width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.test_details_container h2 {
  color: #333;
  margin: 0;
}

.test_details_container p {
  margin: 8px 0;
}

.test_details_container img {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}

.test_details_container ul {
  list-style-type: none;
  padding: 0;
}

.test_details_container li {
  margin-bottom: 8px;
}

.tests_container_close_btn {
  align-self: self-end;
  border: 1px solid #eee;
  border-radius: 100%;
  margin: 0.5rem;
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  position: absolute;
  top: 5px;
  right: 5px;
}

/* CSS */
.test_details_container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.test_details_container h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

.test_details_container p {
  color: #666;
  margin-bottom: 5px;
}

.test_details_container h4 {
  color: #002e81;
  font-size: 20px;
  margin-bottom: 5px;
  margin: 0;
}

.parameters_container {
  margin-top: 20px;
}

.parameters_container h3 {
  font-size: 18px;
  color: #333;
}

.parameters_container ul {
  list-style: inside;
  list-style-type: circle;
  padding: 0;
}

.parameters_container li {
  margin-bottom: 5px;
}

.instructions_container {
  margin-top: 20px;
}

.instructions_container h3 {
  font-size: 18px;
  color: #555;
}

.instructions_container p {
  color: #777;
}

/* Add more styles as needed for additional sections */

@media (max-width: 1084px) {
  .card_main {
    width: calc(33.33% - 0.75rem);
  }
}

@media (max-width: 777px) {
  .card_main {
    width: calc(50% - 0.75rem);
  }
}

@media (max-width: 546px) {
  .card_main {
    width: 100%;
  }

  .description {
    font-size: 14px;
  }

  .plan_btn {
    font-size: 14px;
  }
}
