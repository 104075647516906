.test_main {
  display: flex;
  flex-direction: row;
  margin: 0 4rem;
  gap: 1rem;
}

.tests_heading {
  background-color: #fff;
  text-align: center;
  color: #002e81;
  font-size: 18px;
  margin: 1.5rem 4rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.tests_heading h2 {
  margin: 0;
  color: #002e81;
  font-weight: bold;
  font-size: 1.5rem;
}

.filters_container {
  flex: 1;
  border: 1px solid #eee;
  border-radius: 10px;
}

.filters_container h2 {
  padding: 1rem;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.clear_all_btn {
  border: none;
  color: #002e81;
  background-color: #fff;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid #002e81;
  border-radius: 5px;
  padding: 8px 10px;
}

.filter_item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eee;
}

.filter_item select {
  margin: 1rem;
}

.filter_item h4 {
  margin: 0 1rem;
  margin-top: 1rem;
  font-weight: normal;
}

.filter_item_btns_grp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1rem;
  gap: 1rem;
}

.filter_item_btn {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 0.8rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.filter_item_btn.active {
  background-color: #f5f5f5;
  border: 1px solid #002e81;
  color: #002e81;
  font-weight: bold;
}

.test_container {
  flex: 4;
}

.search_input {
  width: calc(100% - 2rem);
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  font-size: 14px;
  color: gray;
  padding: 1rem;
}

.tests_list {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  overflow: scroll;
  gap: 1rem;
  margin-top: 1rem;
}

.tests_list::-webkit-scrollbar {
  display: none;
}

/* Mobile */
@media (max-width: 770px) {
  .become_member {
    padding: 0.5rem 1rem;
  }

  .test_main {
    margin: 1rem;
  }

  .tests_heading {
    margin: 1rem;
  }

  .filters_container {
    display: none;
  }

  .filters_container_mobile {
    display: block;
  }

  .filters_container_mobile h2 {
    padding: 1rem;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 546px) {
  .test_container {
    padding: 0.5rem;
  }
}
