.contact_main {
  padding: 0 4rem;
  margin: 2rem 0;
}

/* Contact Heading */
.contact_heading {
  background-color: #fff;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #002e81;
}

.contact_heading_h2 {
  margin: 0;
  color: #002e81;
  font-size: 24px;
}

.contact_heading hr {
  height: 0.3rem;
  width: 8rem;
  opacity: 1;
  border-radius: 100rem;
  margin: 0.5rem 0;
  background-color: #002e81;
}

/* Add Card */
.add-card {
  margin-bottom: 10px; /* Increase margin for spacing */
}

/* Contact Content */
.contact-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
}

/* Address */
.div_address {
  flex: 1;
  border: 1px solid #ddd; /* Use a lighter gray border */
  border-radius: 7px;
  padding: 0 1rem;
  padding-top: 1rem;
  background-color: #fff; /* White background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.contact-content h2 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 0.5rem;
}

.contact_address_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-top: 1px solid #eee;
  padding: 1rem 0;
}

.contact_address_div a {
  margin: 0;
  margin-right: 1rem;
  cursor: pointer;
  color: #000;
  text-decoration: none;
}

.contact_address_details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: 1rem;
}

/* Map */
.div_map {
  flex: 1;
}

/* Contact */
.div_contact {
  flex: 1;
  border: 1px solid #ddd; /* Use a lighter gray border */
  border-radius: 7px;
  padding: 1rem;
  background-color: #fff; /* White background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.div_contact p {
  margin: 0;
  margin-bottom: 0.5rem;
}

.form_label {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
}

.form_label input,
textarea {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.button_send {
  background-color: #002e81;
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
  width: 100%;
  color: #fff;
}

/* Mobile Styles */
@media (max-width: 1200px) {
  .donut_div {
    width: 100%;
    margin: 1rem;
  }

  .contact_main {
    padding: 0 1rem;
    margin: 1rem 0;
  }
}
